/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/App/Authentication/components/BasicLayout";

import { useMaterialUIController, setLayout } from "context/index";
import { login } from "api/auth"
import { setAuth } from "hooks/useAuth"
import { axios } from "api/index"


// import { setCredentials } from 'components/Slices/authSlice';
// import { useLoginMutation } from 'components/Slices/authApiSlice';

// Images
import bgImage from "assets/imgs/bg-home.png";
import { useState, useRef, useEffect, useContext } from "react";
import { useDispatch } from 'react-redux'; 
import jwt_decode from "jwt-decode";

function SignInBasic() {
    
    
    const [controller, dispatch] = useMaterialUIController();
    const { userType } = controller;
    const { pathname } = useLocation();

    useEffect(() => {
        setLayout(dispatch, "page");
    }, [pathname]);
    
    const userRef = useRef()
    const errRef = useRef()
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const navigate = useNavigate()
    const location = useLocation();
    const userHome = (role) => {
        switch (role) {
            case "golfer":
                return "/golfer-home";
            case "club":
                return "/club-home";
            default:
                return "/"
        }

    }
    const from = (role) => location.state?.from?.pathname || userHome(role);

    // const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])       

    useEffect(() => {
        setErrMsg('')
    }, [user, pwd]) 
   

    const handleSumbit = async () => {
        try {

            const response = await login({ email: user, password: pwd }); 
            const accessToken = response;
            setAuth(dispatch, accessToken)
            // setUser('')
            // setPwd('')
            const decoded = jwt_decode(accessToken);
            navigate(from(decoded.Role), { replace: true })
        }
        catch (err) {
            console.log(err);
            if (!err?.response?.status) {
                setErrMsg('No Server Response')
            }
            else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password')
            }
            else if (err.response?.status === 401) {
                setErrMsg('Wrong Username or Password, Please try again')
            }
            else {
                setErrMsg('Login Failed')
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setUser(e.target.value)

    const handlePwdInput = (e) => setPwd(e.target.value)


    



  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>
          
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
                  
        
            <MKBox component="form" role="form">
                <MKBox mb={2}>
                    <MKTypography variant="button" color="text" ref={errRef}>{errMsg}</MKTypography>
                    <MKInput name="email" type="email" label="Email" fullWidth onChange={handleUserInput} ref={userRef} />
            </MKBox>
            <MKBox mb={2}>
                <MKInput name="password" type="password" label="Password" fullWidth onChange={handlePwdInput} />
            </MKBox>
            <MKBox mt={4} mb={1}>
                <MKButton variant="gradient" color="success" fullWidth onClick={handleSumbit}>
                sign in
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="success"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>
        </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInBasic;
