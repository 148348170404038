/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "components/Sections/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="golf_course"
                title="Access to Hundreds of Courses"
                description="With one subscription you have access to all the courses on our list"
              />
              <SimpleInfoCard
                icon="tune"
                title="Adjustable Membership"
                description="You can adjust or cancel your plan at any time."
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="paid"
                title="Save Money with a Membership"
                description="Say goodbye to joining on fees and save money throughout the year"
              />
              <SimpleInfoCard
                icon="autorenew"
                title="Credits roll over"
                description="If you don't play golf for a couple of months you don't miss out, credits roll over ready for them warm days!"
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
