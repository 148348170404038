/**
 * 
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================


* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import HomePage from "layouts/pages/website/home";
import CoursesPage from "layouts/pages/website/courses";
import CreditsWorkPage from "layouts/pages/website/howCreditsWork";
import CourseProfilePage from "layouts/pages/website/courseProfile";
import GolferSignUpPage from "layouts/pages/website/signUp";

import SignInPage from "layouts/pages/dashboard/sign-in/customer";
import ClubHomePage from "layouts/pages/dashboard/clubHome";
import ClubSettingsPage from "layouts/pages/dashboard/clubSettings";

import GolferHomePage from "layouts/pages/dashboard/golferHome";

import RequireAuth from "components/RequireAuth";
import PersistLogin from "components/PersistLogin";
// Material Kit 2 PRO React routes
import routes from "routes";
import courseRoutes from "course.routes";
import golferRoutes from "golfer.routes";
// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
 
// Material Dashboard 2 PRO React examples
import Sidenav from "components/Sections/Sidenav";
 

// RTL plugins 


// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/imgs/logo.png";
import brandDark from "assets/imgs/logo.jpg";


export default function App() {
  const { pathname } = useLocation();
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        layout,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
        userType
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
 


    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    const rednerSideNav = () => {
        if (userType === "club") {
            return <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="Club Up"
                routes={courseRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />
        }
        if (userType === "golfer") {
            return <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="Club Up"
                routes={golferRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />
        }
        return "";
    };

    


  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    return (
    <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
              <>
                    {rednerSideNav()}
              </>
          )}
      <Routes>
        
                {/* public routes */}
        <Route element={<PersistLogin />}>
                    
            <Route path="/" element={<HomePage />} />
            <Route path="/Courses" element={<CoursesPage />} />
            <Route path="/How-Credits-Work" element={<CreditsWorkPage />} />
            <Route path="/Courses/:slug" element={<CourseProfilePage />} />
            <Route path="/login" element={<SignInPage />} />
            <Route path="/signup" element={<GolferSignUpPage />} />
                {/* protected routes for club */}
            <Route element={<RequireAuth allowedRole="club" />}>
                <Route path="/club-home" element={<ClubHomePage />} />
                <Route path="/club-settings" element={<ClubSettingsPage />} />
            </Route>
                {/* protected routes for golfer */}
            <Route element={<RequireAuth allowedRole="golfer" />}>
                <Route path="/golfer-home" element={<GolferHomePage />} />
                <Route path="/golfer-settings" />
            </Route>
        </Route>
      </Routes>
            </ThemeProvider>
  );
}
