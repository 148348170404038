/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import MDButton from "components/MDButton";



function ProfileInfo(golfer) {
    const { firstname, lastname, points } = golfer.golfer
   
    return (
        <Card id="profile-info" sx={{ overflow: "visible" }}>
            <MDBox p={3}>
                <MDBox mb={1}>
                    <MDTypography variant="h3" fontWeight="bold">
                        Welcome {firstname} {lastname}
                    </MDTypography>
                </MDBox>

                <MDBox mt={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                        Credits:{points}
                    </MDTypography>
                </MDBox>
                
            </MDBox>
        </Card>
    );
}

export default ProfileInfo;
