import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentToken } from "components/Slices/authSlice"
import { useMaterialUIController } from "context/index";
import jwt_decode from "jwt-decode";

const RequireAuth = ({ allowedRole }) => {
    
    const location = useLocation()
    const [controller, dispatch] = useMaterialUIController();
    const { accessToken } = controller;
    let decoded;
    try {
        decoded = jwt_decode(accessToken);
    }
    catch (err) {
        console.log(err)
    }
    
    return (
        decoded?.Role === allowedRole
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}
export default RequireAuth