import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function Completed() {
    
    return (
        <MDBox mt={8} >
            <MDTypography variant="h2" textAlign="center" mb={2}>
                Welcome to Club Up!
            </MDTypography>
            <MDTypography variant="h4" textAlign="center" mb={5}>
                Account has successfully been created, now get out on the course
            </MDTypography>
        </MDBox>
    )
}

export default Completed;