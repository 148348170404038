import { apiURL, axios } from 'api/index';



const getMapCourses = async (bounds) => {
    try {
        const res = await axios.post(`${apiURL}/api/Courses/MapCourses`, bounds)
        return res.data

    } catch (err) { console.log(err) }
    return [];
}

const getCourseBySlug = async (slug) => {
    const res = await axios.get(`${apiURL}/api/Courses/slug/${slug}`)
    return res.data
}

const getCourse = async (axiosPrivate) => {
    const res = await axiosPrivate.get(`${apiURL}/api/Courses`)
    return res.data
}

const updateCourse = async (axiosPrivate, course) => {
    const res = await axiosPrivate.post(`${apiURL}/api/Courses/MapCourses`, course)
    return res.data
}
const saveCourseImage = async (axiosPrivate,images, type) => {
    
    const request = new FormData();
    let existingIndex = 0;
    images.forEach((img,i) => {
        console.log(img)
        if (img.isFile === true) {
            request.append("Images", img)
        }
        else {
            request.append(`ExistingImages[${existingIndex}].Id`, img.id)
            request.append(`ExistingImages[${existingIndex}].URL`, img.url)
            existingIndex++;
        }

    })
    request.append("ImageType", type)
    
    const res = await axiosPrivate.post(`${apiURL}/api/Courses/images`, request,
        {
            Headers: { 'content-type': 'multipart/form-data' }
        });
    return res.data
}

export default getMapCourses;
export { getMapCourses, getCourseBySlug, getCourse, saveCourseImage, updateCourse }