
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function ListCard({ id, image, name, description, holes, par, slug }) {
    return (
        <a href={`/courses/${slug}`}>
        <Card sx={{ m: { lg: 2 } }} variant="outlined"  data-id={id} className="map-list-card" >
            <Grid container>
                <Grid item xs={5} md={6} lg={4}>
                    <MKBox width="100%" pt={2} pb={1} px={2} sx={{ alignContent: 'center' }}>
                        <MKBox
                            component="img"
                            src={image}
                            alt={name}
                            width="100%"
                            borderRadius="md"
                            shadow="lg"
                        />
                    </MKBox>
                </Grid>
                <Grid item xs={7} md={6} lg={8} sx={{ my: "auto" }}>
                    <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={2} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
                        <MKTypography variant="h5">{name}</MKTypography>
                        <MKBadge badgeContent={`${holes} Holes`}  color="success"/>
                        <MKBadge badgeContent={`Par: ${par}`} color="success"/>
                        <MKTypography variant="body2" color="text" mt={{ lg: 2 }} sx={{ display: { xs: 'none', lg:'-webkit-box' } }} className="list-item-description">
                            {description}
                        </MKTypography>
                    </MKBox>
                </Grid> 
            </Grid>
            </Card>
            </a>
        )
}
ListCard.propTypes = {
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    holes: PropTypes.number.isRequired,
    par: PropTypes.number.isRequired,
};
export default ListCard;