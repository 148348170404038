/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/Sections/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Sections/Navbars/DashboardNavbar";
import Footer from "components/Sections/Footers/DashboardFooter";
import ProfilesList from "components/Sections/Lists/ProfilesList";
import PurchaseWidget from "pages/App/GolferHome/components/PurchaseWidget";
import ProfileInfo from "pages/App/GolferHome/components/ProfileInfo";
// Sales dashboard components
// import ChannelsChart from "pages/App/ClubHome/components/ChannelsChart";

// Data
import defaultLineChartData from "pages/App/ClubHome/data/defaultLineChartData";
import { useSelector } from "react-redux"
import { Link, useNavigate, useLocation } from "react-router-dom"
import useRefreshToken from 'hooks/useRefreshToken';
import { useMaterialUIController } from "context/index";
import getGolfer from "api/golfers";


import useAxiosPrivate from 'hooks/useAxiosPrivate'

function GolferHome() {

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [golfer, setGolfer] = useState('');
    useEffect(() => {
        
        getGolfer().then((data) => {
            console.log(data);
            setGolfer(data);
        });
        
        
    }, [])

    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        
                            {
                            Date.parse(golfer.expiryDate) < Date.now() ?
                                <Grid item xs={12} sm={12} lg={8}>
                                    <PurchaseWidget />
                                </Grid> :
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <ProfileInfo golfer={golfer} />
                                    </Grid>
                            }
                            
                        
                        <Grid item xs={12} sm={6} lg={4}>
                            <ProfilesList
                                title="Current Bookings"
                                profiles={[
                                    {
                                        image: "https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_3.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_20396.png",
                                        name: "Golf Club",
                                        description: "11/10/2022 9:30am Booking",
                                        action: {
                                            type: "internal",
                                            route: "/club-home",
                                            color: "warning",
                                            label: "cancel",
                                        },
                                    },
                                    {
                                        image: "https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_3.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_20396.png",
                                        name: "Bob Westwood",
                                        description: "11/10/2022 Booking Request",
                                        action: {
                                            type: "internal",
                                            route: "/club-home",
                                            color: "info",
                                            label: "cancel",
                                        },
                                    },
                                    {
                                        image: "https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_3.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_20396.png",
                                        name: "Steve Westwood",
                                        description: "11/10/2022 Booking Request",
                                        action: {
                                            type: "internal",
                                            route: "/club-home",
                                            color: "info",
                                            label: "cancel",
                                        },
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
                
                
            </MDBox>
            <Footer  />
        </DashboardLayout>
    );
}

export default GolferHome;
