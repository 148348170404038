import Container from"@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ExampleCard from "components/Sections/Cards/CreditsCards/ExampleCard";

import tony from "assets/imgs/ExampleUsers/finau.png";
import lydia from "assets/imgs/ExampleUsers/Ko.png";
import lee from "assets/imgs/ExampleUsers/westwood.png";

function CreditUsageExamples() {

    return (
        <MKBox component="section" py={{ xs: 6, md: 12 }}>
            <Container>
                <Grid container item xs={12} justifyContent="center">
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <Stack>
                            <MKTypography variant="h2">
                                How to use your credits
                            </MKTypography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 10, mb: 6 }}>
                    <Grid item xs={12} md={4}>
                        <ExampleCard
                            image={tony}
                            name="Tony Finau"
                            plan="55 Tee Point Plan"
                            blurb="Tony during the winter months doesnt play as much golf. However, with ClubUp it allows him to top up on lessons so his standard membership isn't going to waste."
                            items={[{ activity: "Golf Lesson X2", credits: 20 }, { activity: "Golf Store", credits: 35 }]}                           
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ExampleCard
                            image={lydia}
                            name="Lydia Ko"
                            plan="85 Tee Point Plan"
                            blurb="Lydia travels a lot with work and enjoys golf, with ClubUp this allows her to visit new courses on her travels under one membership"
                            items={[{ activity: "Round of Golf X4", credits: 80 }, { activity: "Golf Store", credits: 5 }]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ExampleCard
                            image={lee}
                            name="Lee Westwood"
                            plan="20 Tee Point Plan"
                            blurb="Lee enjoys a casual round of golf once a month and saves money while doing it."
                            items={[{ activity: "Round of Golf X1", credits: 20 }]}
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
        );

}

export default CreditUsageExamples;