import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { getSubscriptionProducts, purchaseSubscription } from "api/products"
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
// @material-ui core components
import Card from "@mui/material/Card";




function PurchaseWidget(stripeId) {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const navigate = useNavigate();
    useEffect(() => {

        getSubscriptionProducts().then((data) => {
            setProducts(data)
            console.log(data) 
        })

    }, []);
    const handlePurchase = async () => {
        try {
            const response = await purchaseSubscription(selectedProduct);
            window.location.href = response;
        }
        catch (err) {
            if (!err?.response?.status) {
                console.log('')
            }
            else if (err.response?.status === 400) {
                console.log(err.response?.data)
            }
            else {
                console.log('purchase failed')
            }
        }
    }
    const handleSelectProduct = (id) => {
        setSelectedProduct(id)
    }

    return (
        <Card id="purchase-widget" sx={{ overflow: "visible" }}>
            <MDBox mt={8} p={3}>
                <MDTypography variant="h2" textAlign="center" mb={2}>
                    Welcome to Club Up!
                </MDTypography>
                <MDTypography variant="h4" textAlign="center" mb={5}>
                    Pick a plan to gain access to credits
                </MDTypography>
                <Grid container spacing={2} justifyContent="center">
                    {
                        products.map((prod) => (
                            <Grid key={prod.id} item xs={12} lg={4}>
                                <MDBox
                                    key={prod.id}
                                    justifyContent="center"
                                >
                                    <MDBox>
                                        <MDTypography variant="h3" textAlign="center" mb={2}>
                                            {prod.name}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox>
                                        <MDTypography variant="caption">
                                            {prod.description}
                                        </MDTypography>
                                    </MDBox>
                                    <MDTypography fontWeight="bold">
                                        £{prod.price}
                                    </MDTypography>
                                    <MDButton variant={prod.stripeID === selectedProduct ? 'contained' : 'outlined'} color="info" onClick={() => { handleSelectProduct(prod.stripeID) }}>
                                        {prod.stripeID === selectedProduct ? 'Selected' : 'Select'}
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        ))
                    }
                </Grid>
                <MDButton variant="contained" onClick={() => { handlePurchase() }}>Purchase</MDButton>
            </MDBox>
        </Card>
    );
}

export default PurchaseWidget;
