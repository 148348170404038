// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultReviewCard from "components/Sections/Cards/ReviewCards/DefaultReviewCard";

// Images
import review1 from "assets/imgs/Reviews/sam-bishop.jpg";
import team2 from "assets/images/marie.jpg";
import team3 from "assets/images/team-2.jpg";

function Testimonials() {
    return (
        <MKBox component="section" py={6}>
            <Container>
                <Grid container item xs={12} lg={6} justifyContent="center" mx="auto" textAlign="center">
                    <MKTypography variant="h2" mb={2}>
                        Don't just take our word for it!
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                        See what others are saying about Club Up!
                    </MKTypography>
                </Grid>
                <Grid container spacing={3} mt={8}>
                    <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                        <DefaultReviewCard
                            image={review1}
                            name="Sam Bishop"
                            review="Club Up has really brought golffing to the 21st centry! I can book multiple courses under one memebrship while saving money."
                            rating={5}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                        <DefaultReviewCard
                            color="primary"
                            image={review1}
                            name="Sam Bishop"
                            review="As i dont play much in winter it's great that my points stack so i don't lose out on the winter months when its' to cold to play!"
                            rating={5}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                        <DefaultReviewCard
                            image={review1}
                            name="Sam Bishop"
                            review="Club Up works great with my busy work schedule, I can travel around and use my points at many different courses."
                            rating={5}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 6 }} />
               
            </Container>
        </MKBox>
    );
}

export default Testimonials;