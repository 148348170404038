/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Rental page components
import FaqCollapse from "pages/Website/Courses/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="Where can I use my credits?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              You can use your credits to book tee times at any of our partner clubs. Simply click 'find a course' to see the locations and book a time that works for you.
            </FaqCollapse>
            <FaqCollapse
              title="How do I save by using ClubUp?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
                          As a ClubUp member, you'll have access to special rates you won't find anywhere else. Plus, we're always adding new features and benefits, so you'll be able to use your credits on much more than just tee times soon! Stay up to date by signing up for free.
            </FaqCollapse>
            <FaqCollapse
              title="How do I know how much a tee time costs?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Once you sign up, you'll be able to see the credit price for tee times at each of our partnered clubs. Prices may vary based on factors like popularity and time of day.
            </FaqCollapse>
            <FaqCollapse
              title="What if I run out of credits?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              If you find yourself running low on credits, don't worry! You can easily top up your account in the "add credits" section of your account.
            </FaqCollapse>
            <FaqCollapse
              title="What if I don't use all my credits?"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              Any unused credits will roll over each month. For example, if you're on a 20 credit plan, you can roll over a maximum of 20 credits into the next month, giving you a total of 40 credits. This will continue indefinitely unless you cancel your membership, at which point you won't accrue any new credits but you can continue to spend any remaining credits until you run out.
            </FaqCollapse>
            <FaqCollapse
                title="What happens if I cancel a booking?"
                open={collapse === 6}
                onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
            >
                You can cancel a booking for free up until 12 hours before your tee time. However, cancellations made within 12 hours of the tee time will incur a cancellation fee.
            </FaqCollapse>
            <FaqCollapse
                title="What is your membership cancellation policy, and how do I cancel?"
                open={collapse === 7}
                onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
            >
                You can cancel your membership at any time by following the steps outlined here. Your credits will remain valid until they are all spent.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
