/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MapIcon from '@mui/icons-material/Map';
import FilterListIcon from '@mui/icons-material/FilterList';
import ListIcon from '@mui/icons-material/List';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PageLayout from "components/Sections/LayoutContainers/PageLayout";
// import Container from "@mui/material/Container";
// import Autocomplete from "@mui/material/Autocomplete";

// import SearchIcon from "@mui/icons-material/Search";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Sections/Navbars/DefaultNavbar";
// import DefaultFooter from "components/Sections/Footers/DefaultFooter";

// import Places from "pages/Website/Courses/sections/Places";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
// import bgImage from "assets/imgs/bg-courses.png";

// API
import { getMapCourses } from "api/courses";


// import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import  './map.css'
import ListCard from 'components/Sections/ListCard';
import courseImg from "assets/imgs/bg-courses.png";
// import { render } from 'react-dom';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// eslint-disable-next-line
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = 'pk.eyJ1Ijoic2FtLW1hc3NjcmVhdGlvbnMiLCJhIjoiY2w2a3N6dWZ1MDR6eDNwbWVhaTl2a3luOSJ9.uivZXh5hl60Blw_SBPrbIg';

function Courses() {
    // MAP CONSTS
    const mapContainer = useRef("");
    const map = useRef("");
    const geocoderInput = useRef("");
    const stateMakrers = [];
    const [filterDrawer, setFilterDrawer] = useState(false);
    const [lng, setLng] = useState(52.4862);
    const [lat, setLat] = useState(1.8904);
    const [zoom] = useState(9);
    const [list, setList] = useState([]);
    const [mapMarkers, setMarkers] = useState(stateMakrers);
    const [selectedCourseName, setSelectedCourseName] = useState('');
    const [selectedCourseImg, setSelectedCourseImg] = useState(courseImg);
    const [selectedCourseSlug, setSelectedCourseSlug] = useState('');
    const mms = [];

    const [filter, setFilter] = useState('map');
    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
        console.log("app height")
    }
    window.addEventListener('resize', appHeight)
    appHeight()
    const handleFilter = () => {
        if (filter === 'map') {
            setFilter('list');
        }
        else {
            setFilter('map');
        }
        
    };
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setFilterDrawer(open);
    };
 


    const renderListItem = (data) => {
        let image = courseImg;
            data.images.forEach((img) => {
                if (img.imageType === 0) {
                    image = img.url
                }
            });
            return <ListCard key={data.id} name={data.name} description={data.description} id={data.id} image={image} holes={data.holes} par={data.par} slug={data.slug} />
        };


    const handleSearchClick = () => {
        const sw = map.current.getBounds().getSouthWest();
        const ne = map.current.getBounds().getNorthEast();
        const nw = map.current.getBounds().getNorthWest();
        const se = map.current.getBounds().getSouthEast();

        const bounds = [{ longitude: sw.lng, latitude: sw.lat }, { longitude: nw.lng, latitude: nw.lat }, { longitude: ne.lng, latitude: ne.lat }, { longitude: se.lng, latitude: se.lat }, { longitude: sw.lng, latitude: sw.lat }];

        mms.forEach((marker) => marker.remove())
        mapMarkers.forEach((marker) => marker.remove())
        setMarkers([]);
        setList([]);
        while (mms.length > 0) {
            mms.pop();
        }
        getMapCourses(bounds).then((data) => {
            data.forEach((marker) => {
                const listItem = renderListItem(marker);
                setList(arr => [...arr, listItem]);
                const m = new mapboxgl.Marker({ color:"#000000"})
                    .setLngLat([marker.longitude, marker.latitude])
                    .addTo(map.current)
                const markerel = m.getElement()
                markerel.addEventListener('click', (e) => {
                    console.log(marker)
                    setSelectedCourseName(marker.name)
                    setSelectedCourseSlug(marker.slug)
                    setSelectedCourseImg(courseImg)
                    marker.images.forEach((img) => {
                        if (img.imageType === 0) {
                            setSelectedCourseImg(img.url)
                        }
                    });
                })
                mms.push(m);
            });
        });
        setMarkers(mms);
    };
    const renderMap = () => {
        // if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/sam-masscreations/clb51ut6e001g14nvjdgt6e8t',
            center: [lng, lat],
            zoom
        });
        map.current.addControl(new mapboxgl.NavigationControl());
        map.current.on('dragstart', () => {
            setSelectedCourseName('');
        });
        handleSearchClick();
    }; 
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            handleSearchClick();
        });
        
    }, [])

    useEffect(() => {
        renderMap();
    }, [lat, lng]);
    useEffect(() => {
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl,
            flyTo: true,
            addressAccuracy:"place"
        });
        geocoder.on('result', async (results) => {
            
            map.current.jumpTo({
                center: results.result.center,
                zoom: 10
            })
            handleSearchClick();
        })
        geocoderInput.current.appendChild(geocoder.onAdd(map.current));
    }, [])
  return (
      <>
          <PageLayout className="course-page">
          <MKBox variant="gradient" bgColor="white" shadow="sm" py={0.25}>
              <DefaultNavbar
                  routes={routes}
                  action={{
                      type: "internal",
                      route: "/login",
                      label: "Login",
                      color: "success",
                  }}
                  transparent
                  relative
                  
                  />

                  <Grid container className="map-search-options" spacing={{ xs: 0 }}>
                      <Grid item xs={12} >
                          <div ref={geocoderInput} />
                      </Grid>
                      <Grid container item xs={12} spacing={1} m={0.5}>
                          <Grid item>
                              <MKButton size="small" variant="gradient" color="primary" onClick={toggleDrawer(true)}>Filters</MKButton>
                          </Grid>
                          <Grid item display={{ xs: "block", lg: "none" }}>
                              <MKButton size="small" variant="gradient" color="primary" onClick={() => handleFilter()}>{filter === "map" ? "List" : "Map"}</MKButton>
                            </Grid>
                        
                      </Grid>
                  </Grid>
                  <div>
                      <React.Fragment key='left'>
                          
                          <Drawer
                              anchor='left'
                              open={filterDrawer}
                              onClose={toggleDrawer(false)}
                          >
                              <Box
                                  sx={{ width: '75vw', maxWidth: 400 }}
                                  p={2}
                                  role="presentation"
                                  onClick={toggleDrawer(false)}
                                  onKeyDown={toggleDrawer(false)}
                              >
                                  <MKTypography variant="h6">Filters</MKTypography>
                                  
                              </Box>
                          </Drawer>
                      </React.Fragment>

                  </div>
          </MKBox>
     
      
              
              <div className="bottom-map-container">
                  
                  <div className="bottom-map-container-child">
                     
                      <Grid container >
                          <Grid item xs={12} lg={6} sx={{ display: (filter === 'list' ? { xs: 'block' } : { xs: 'none', lg: 'block' }) }}>
                                  
                                  <div className="map-list-container" >
                                  {list.length > 0 ? (list) :
                                      (
                                          <div><h3>No Results Found</h3><p>Please adjust filters or search a new area</p></div>
                                        )
                                    }
                                  </div >
                    </Grid>
                          <Grid item xs={12} lg={6} sx={{ position: 'relative', display: (filter === 'map' ? { xs: 'block' } : { xs: 'none', lg: 'block' }) }} >
                              
                              <MKButton type="button" className="search-here-button" onClick={handleSearchClick} >Search This Area</MKButton>
                              <Card className="map-course-quickview" sx={{ display: (selectedCourseName !== '' ? 'block' : 'none')}}>
                                  <Grid container>
                                      <Grid item xs={4} md={4} >
                                          <MKBox
                                              component="img"
                                              src={selectedCourseImg}
                                              alt={selectedCourseName}
                                              width="100%"
                                              borderRadius="md"
                                              shadow="lg"
                                          />
                                      </Grid>
                                      <Grid item xs={8} lg={8} >
                                          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={2} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
                                              <MKTypography variant="h6">{selectedCourseName}</MKTypography>
                                              <MKButton component={Link} to={`/courses/${selectedCourseSlug}`} color="success" size="small" >
                                                  Book
                                              </MKButton>
                                          </MKBox>
                                      </Grid>
                                    </Grid>
                                  
                              </Card>
                        <div ref={mapContainer} className="map-container" />
                    </Grid>
                  </Grid>
              </div>
          </div>
          

          </PageLayout>  
     
    </>
  );
}

export default Courses;
