// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Sections/Navbars/DefaultNavbar";
import DefaultFooter from "components/Sections/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Sections
import CreditUsageExamples from "pages/Website/HowCreditsWork/sections/CreditUsageExamples";
import Faq from "pages/Website/HowCreditsWork/sections/Faq";
import Pricing from "pages/Website/HowCreditsWork/sections/Pricing";

function CreditsWork() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "internal",
                    route: "/login",
                    label: "login",
                    color: "success",
                }}
                sticky
            />
            <MKBox
                minHeight={{xs:"75vh", lg:"60vh"}}
                width="100%"
                sx={{
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent={{ xs: "center", md: "center" }}
                        sx={{ textAlign: { xs: "center", md: "center" } }}
                    >
                        <MKTypography
                            variant="h1"
                            color="black"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Choose a Plan
                        </MKTypography>
                        <MKTypography
                            variant="body1"
                            color="black"
                            mt={1}
                            px={{ md: 12 }}
                        >
                            Whether you play 3 times a week, or once a month <br /><br />
                            Our membership plans are designed to suit your needs and budget, so you can get the most out of your passion for golf.<br /><br />
                            Join the thousands of golf enthusiasts who are saving money, enjoying greater flexibility, and experiencing the most beautiful courses in the country
                        </MKTypography>
                       
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: { xs: -2, lg: -8 },
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)", 
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Pricing />
                <CreditUsageExamples/>
                <Faq />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default CreditsWork;
