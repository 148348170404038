/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";

function ExampleCard({ image, name, plan, blurb, items }) {
    const renderItems = items.map(({ activity, credits }) =>
    (
        <MKBox key={activity}>
            <Grid container justifyContent="space-between" sx={{ borderTop: 1 }}>
                <Grid item >
                    <MKTypography variant="body1" >
                        {activity}
                    </MKTypography>
                </Grid>
                        <Grid item>
                    <MKTypography variant="subtitle1" mb={2} >
                         {credits} Tee Points 
                    </MKTypography>
                </Grid>
            </Grid>
        </MKBox>
            )
    )


    return (
        <Card sx={{mt:10}}>
            <MKBox display="grid" justifyContent="center" position="relative" mt={-7}>
                <MKAvatar
                    src={image}
                    alt={name}
                    size="xxl"
                    shadow="lg"
                    sx={{ position: "relative", zIndex: 2 }}
                />
                <MKBox
                    width="6.875rem"
                    height="6.875rem"
                    borderRadius="50%"
                    position="absolute"
                    top="3.5%"
                    left="50%"
                    zIndex={1}
                    sx={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                        transform: "translateX(-50%) scale(0.87)",
                        filter: "blur(12px)",
                    }}
                />
            </MKBox>
            <MKBox pt={3} px={3} textAlign="center">
                <MKTypography variant="h3" fontWeight="bold">
                    {name}
                </MKTypography>
                {plan && (
                    <MKTypography variant="h4"  mb={2}>
                        {plan}
                    </MKTypography>
                )}
                <MKTypography variant="body2" color="text" mt={1} mb={2}>
                    {blurb}
                </MKTypography>
                {renderItems}
            </MKBox>
            
        </Card>
    );
}

// Setting default values for the props of SimpleReviewCard
ExampleCard.defaultProps = {
    
    plan:""
};

// Typechecking props for the SimpleReviewCard
ExampleCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    plan: PropTypes.string,
    blurb: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
};

export default ExampleCard;
