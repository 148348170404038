import axios from 'axios';

// const apiURL = "https://localhost:7203"
 const apiURL = "https://api.clubup.co.uk"

export default axios.create({
    baseURL: apiURL
})

const axiosPrivate = axios.create({
    baseURL: apiURL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
})

export { axiosPrivate, axios, apiURL }  