import axois from 'api/index';
import { setAuth } from 'hooks/useAuth'
import { useMaterialUIController } from 'context/index'

const useRefreshToken = () => {

    const [controller, dispatch] = useMaterialUIController();
    const refresh = async () => {
        const response = await axois.get('/api/token/refresh', {
            withCredentials: true
        })
        setAuth(dispatch,  response.data )
        console.log(response.data)
        return response.data
    }
    return refresh;
}

export default useRefreshToken