import { useMaterialUIController, setAccessToken, setUserType } from "context/index";
import jwt_decode from "jwt-decode";

const useAuth = () => { 
    const [controller, dispatch] = useMaterialUIController();
    return controller;
}
const setAuth = (dispatch, token) => {
    setAccessToken(dispatch, token);
    try {
        const decoded = jwt_decode(token);
        setUserType(dispatch,decoded.Role);
    }
    catch (err) {
        console.log(err)
    }
    
}

export { useAuth, setAuth };