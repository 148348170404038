import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, refreshToken:null },
    reducers: {
        setCredentials: (state, action) => {
            const { user, accessToken, refreshToken } = action.payload
            console.log(action)
            state.user = user // eslint-disable-line no-param-reassign
            state.token = accessToken // eslint-disable-line no-param-reassign
            state.refreshToken = refreshToken // eslint-disable-line no-param-reassign
        },
        logOut: (state, action) => {
            state.user = null // eslint-disable-line no-param-reassign
            state.token = null // eslint-disable-line no-param-reassign
            state.refreshToken = null // eslint-disable-line no-param-reassign
            console.log(action)
        }
    } 
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user // eslint-disable-line no-param-reassign
export const selectCurrentToken = (state) => state.auth.token // eslint-disable-line no-param-reassign
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken // eslint-disable-line no-param-reassign