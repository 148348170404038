/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "pages/Website/SignUp/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    handicap,
    membershipNo,
    username,
    email,
    password,
    repeatPassword,
    address1,
    address2,
    city,
    county,
    postcode,
    productId
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [handicap.name]: "",
  [membershipNo.name]: "",
  [username.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [county.name]:"",
  [postcode.name]: "",
  [productId.name]: ""
};

export default initialValues;
