import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimplePricingCard from "components/Sections/Cards/PricingCards/SimplePricingCard";

import bgImage from "assets/imgs/bg-courses.png";

function Pricing() {
    

    return (
        <MKBox component="section" py={{ xs: 0, lg: 7 }}>
            <MKBox
                borderRadius="xl"
                shadow="lg"
                sx={{
                    backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                }}
            >
                <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
                    <Grid
                        container
                        item
                        flexDirection="column"
                        alignItems="center"
                        xs={12}
                        md={8}
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKBadge
                            badgeContent="pricing"
                            variant="gradient"
                            container
                            color="dark"
                            sx={{ mb: 1 }}
                        />
                        <MKTypography variant="h3" color="white" mb={2}>
                            See our pricing
                        </MKTypography>
                        <MKTypography variant="body2" color="white">
                            Our memberships
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox mt={{xs:-10, lg:-16}}>
                <Container>
                    <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={4}>
                                <SimplePricingCard
                                    variant="gradient"
                                    color="primary"
                                    title="20 Credits"
                                    description="The great ClubUp entry membership"
                                    price={{ value: "£29", type: "month" }}
                                    action={{ type: "internal", route: "/signup", label: "Sign Up" }}
                                    specifications={[
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SimplePricingCard
                                    variant="gradient"
                                    color="primary"
                                    title="36 Credits"
                                    description="caption about package"
                                    price={{ value: "£49", type: "month" }}
                                    action={{ type: "internal", route: "/signup", label: "Sign Up" }}
                                    specifications={[
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SimplePricingCard
                                    variant="gradient"
                                    color="primary"
                                    title="45 Credits"
                                    description="caption about package"
                                    price={{ value: "£59", type: "month" }}
                                    action={{ type: "internal", route: "/signup", label: "Sign Up" }}
                                    specifications={[
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SimplePricingCard
                                    variant="gradient"
                                    color="primary"
                                    title="61 Credits"
                                    description="caption about package"
                                    price={{ value: "£79", type: "month" }}
                                    action={{ type: "internal", route: "/signup", label: "Sign Up" }}
                                    specifications={[
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SimplePricingCard
                                    variant="gradient"
                                    color="primary"
                                    title="80 Credits"
                                    description="caption about package"
                                    price={{ value: "£99", type: "month" }}
                                    action={{ type: "internal", route: "/signup", label: "Sign Up" }}
                                    specifications={[
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SimplePricingCard
                                    variant="gradient"
                                    color="primary"
                                    title="110 Credits"
                                    description="caption about package"
                                    price={{ value: "£129", type: "month" }}
                                    action={{ type: "internal", route: "/signup", label: "Sign Up" }}
                                    specifications={[
                                       
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </MKBox>
                   
                </Container>
            </MKBox>
        </MKBox>
    );
}

export default Pricing;
