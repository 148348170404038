/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/Sections/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Sections/Navbars/DashboardNavbar";
import Footer from "components/Sections/Footers/DashboardFooter";
import DefaultStatisticsCard from "components/Sections/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "components/Sections/Charts/LineCharts/DefaultLineChart";
import ProfilesList from "components/Sections/Lists/ProfilesList";

// Sales dashboard components
// import ChannelsChart from "pages/App/ClubHome/components/ChannelsChart";

// Data
import defaultLineChartData from "pages/App/ClubHome/data/defaultLineChartData";
import { useSelector } from "react-redux"
import { Link, useNavigate, useLocation } from "react-router-dom"
import useRefreshToken from 'hooks/useRefreshToken';
import { useMaterialUIController } from "context/index";

import useAxiosPrivate from 'hooks/useAxiosPrivate'

function Sales() {

    const axiosPrivate = useAxiosPrivate();
    const [weathers, setWeathers] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const isMounted = true;
        const abortController = new AbortController();

        
    }, []);


    // DefaultStatisticsCard state for the dropdown value
    const [salesDropdownValue, setSalesDropdownValue] = useState("1 Sept - 7 Sept");
    const [customersDropdownValue, setCustomersDropdownValue] = useState("6 May - 7 May");
    const [revenueDropdownValue, setRevenueDropdownValue] = useState("6 May - 7 May");

    // DefaultStatisticsCard state for the dropdown action
    const [salesDropdown, setSalesDropdown] = useState(null);
    const [customersDropdown, setCustomersDropdown] = useState(null);
    const [revenueDropdown, setRevenueDropdown] = useState(null);

    // DefaultStatisticsCard handler for the dropdown action
    const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
    const closeSalesDropdown = ({ currentTarget }) => {
        setSalesDropdown(null);
        setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
    };
    const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
    const closeCustomersDropdown = ({ currentTarget }) => {
        setCustomersDropdown(null);
        setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
    };
    const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
    const closeRevenueDropdown = ({ currentTarget }) => {
        setRevenueDropdown(null);
        setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
    };

    // Dropdown menu template for the DefaultStatisticsCard
    const renderMenu = (state, close) => (
        <Menu
            anchorEl={state}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={Boolean(state)}
            onClose={close}
            keepMounted
            disableAutoFocusItem
        >
            <MenuItem onClick={close}>Last 7 days</MenuItem>
            <MenuItem onClick={close}>Last week</MenuItem>
            <MenuItem onClick={close}>Last 30 days</MenuItem>
        </Menu>
    );

    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <DefaultStatisticsCard
                                title="Points Used"
                                count="300 Tee Points"
                                percentage={{
                                    color: "success",
                                    value: "+55%",
                                    label: "since last month",
                                }}
                                dropdown={{
                                    action: openSalesDropdown,
                                    menu: renderMenu(salesDropdown, closeSalesDropdown),
                                    value: salesDropdownValue,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DefaultStatisticsCard
                                title="Bookings"
                                count="125"
                                percentage={{
                                    color: "success",
                                    value: "+12%",
                                    label: "since last month",
                                }}
                                dropdown={{
                                    action: openCustomersDropdown,
                                    menu: renderMenu(customersDropdown, closeCustomersDropdown),
                                    value: customersDropdownValue,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DefaultStatisticsCard
                                title="avg. revenue"
                                count="&#163;1.200"
                                percentage={{
                                    color: "secondary",
                                    value: "+� 213",
                                    label: "since last month",
                                }}
                                dropdown={{
                                    action: openRevenueDropdown,
                                    menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                                    value: revenueDropdownValue,
                                }}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <ProfilesList
                                title="Booking Requests"
                                profiles={[
                                    {
                                        image: "https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_3.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_20396.png",
                                        name: "Lee Westwood",
                                        description: "11/10/2022 Booking Request",
                                        action: {
                                            type: "internal",
                                            route: "/club-home",
                                            color: "info",
                                            label: "reply",
                                        },
                                    },
                                    {
                                        image: "https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_3.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_20396.png",
                                        name: "Bob Westwood",
                                        description: "11/10/2022 Booking Request",
                                        action: {
                                            type: "internal",
                                            route: "/club-home",
                                            color: "info",
                                            label: "reply",
                                        },
                                    },
                                    {
                                        image: "https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_3.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_20396.png",
                                        name: "Steve Westwood",
                                        description: "11/10/2022 Booking Request",
                                        action: {
                                            type: "internal",
                                            route: "/club-home",
                                            color: "info",
                                            label: "reply",
                                        },
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={8}>
                            <DefaultLineChart
                                title="Revenue"
                                description={
                                    <MDBox display="flex" justifyContent="space-between">
                                        <MDBox display="flex" ml={-1}>
                                            <MDBadgeDot color="info" size="sm" badgeContent="18 Hole Bookings" />
                                            <MDBadgeDot color="dark" size="sm" badgeContent="Pro Shop" />
                                        </MDBox>
                                        <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                                            <Tooltip title="See which ads perform better" placement="left" arrow>
                                                <MDButton
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    circular
                                                    iconOnly
                                                >
                                                    <Icon>priority_high</Icon>
                                                </MDButton>
                                            </Tooltip>
                                        </MDBox>
                                    </MDBox>
                                }
                                chart={defaultLineChartData}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
                <ul>
                    {weathers.map((weather, i) => <li key={weather?.date}>{weather?.date} : {weather?.summary}</li>)}
                </ul>
                
            </MDBox>
            <Footer  />
        </DashboardLayout>
    );
}

export default Sales;
