import { apiURL, axios, axiosPrivate } from 'api/index';

const getGolfer = async () => {

    const res = await axiosPrivate.get(`${apiURL}/api/users/golfer`)
        return res.data


}

export default getGolfer
