// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/imgs/logo.jpg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Club Up",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/about" },
        { name: "How It Works", href: "/how-credits-work" },
          { name: "Corporate Memberships", href: "/Corporate" }
      ],
    },
    {
      name: "Partners",
      items: [
          { name: "register your Club", href: "/" }
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/contact-us" }
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/terms" },
        { name: "privacy policy", href: "/privacy" }
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}
      
      
    </MKTypography>
  ),
};
