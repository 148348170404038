import { apiURL, axios } from 'api/index';
import { useAuth, setAuth } from 'hooks/useAuth';
import { useMaterialUIController } from "context/index";

const login = async (request) => {
    
        const res = await axios.post(`${apiURL}/api/Users/Login`, request,
            {
                Headers: { 'content-type': 'application/json' },
                withCredentials: true
            });
        console.log(res.data);
        return res.data 

}

const logout = async (dispatch) => {
    setAuth(dispatch, '');
    try {
        const repsonse = await axios.get(`${apiURL}/api/Users/Logout`, {
            Headers: { 'content-type': 'application/json' },
            withCredentials: true
        });
    } catch (e) {
        console.log(e);
    }
} 

export { login, logout };