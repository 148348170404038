/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import DashboardLayout from "components/Sections/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Sections/Navbars/DashboardNavbar";
import Footer from "components/Sections/Footers/DashboardFooter";
import BasicInfo from "pages/App/ClubSettings/components/BasicInfo";
import ImageUploader from "pages/App/ClubSettings/components/ImageUploader";
import { getCourse} from "api/courses"
import useAxiosPrivate from "hooks/useAxiosPrivate";

function ClubSettings() {
    const [course, setCourse] = useState({})
    const axiosPrivate = useAxiosPrivate();
    useEffect(async () => {
        const res = await getCourse(axiosPrivate)
        setCourse(res)
    },[])

    return (
        <DashboardLayout>
            <DashboardNavbar /> 
            <MDBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <BasicInfo course={course}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ImageUploader images={course.images} />
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
}

export default ClubSettings;
