/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Images


function ProductImages(images) {
    const [imgs, setImgs] = useState([]);
    const [currentImage, setCurrentImage] = useState('');
    const [imgsViewer, setImgsViewer] = useState(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

    const handleSetCurrentImage = ({ currentTarget }) => {
        setCurrentImage(currentTarget.src);
        setImgsViewerCurrent(Number(currentTarget.id));
    };

    const openImgsViewer = () => setImgsViewer(true);
    const closeImgsViewer = () => setImgsViewer(false);
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

    useEffect(() => {
        console.log(images)
        images.images.forEach((img) => {
            console.log(img)
            if (img.imageType === 0) {
                setCurrentImage(img.url)
            }
            setImgs(currentImgs => [...currentImgs, {src:img.url, id:img.id}])
        })
    }, [images])

    return (
        <MDBox>
            <ImgsViewer
                imgs={imgs}
                isOpen={imgsViewer}
                onClose={closeImgsViewer}
                currImg={imgsViewerCurrent}
                onClickPrev={imgsViewerPrev}
                onClickNext={imgsViewerNext}
                backdropCloseable
            />

            <MDBox
                component="img"
                src={currentImage}
                alt="Course Featured Image"
                shadow="lg"
                borderRadius="lg"
                width="100%"
                onClick={openImgsViewer}
            />
            <MDBox mt={2} pt={1}>
                <Stack direction="row" spacing={3} sx={{ overflow: "auto" }}>
                    {imgs.map((img, i) => (
                        <MDBox
                            key={img.id}
                            component="img"
                            id={img.id}
                            src={img.src}
                            alt="Course Image"
                            borderRadius="lg"
                            shadow="md"
                            width="100%"
                            height="10rem"
                            minHeight="5rem"
                            sx={{ cursor: "pointer", objectFit: "cover" }}
                            onClick={handleSetCurrentImage}
                        />

                    ))}
                </Stack>
            </MDBox>
        </MDBox>
    );
}

export default ProductImages;
