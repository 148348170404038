/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PageLayout from "components/Sections/LayoutContainers/PageLayout";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
// import { setLayout, useMaterialUIController } from "context";
// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Sections/Navbars/DefaultNavbar";
import DefaultFooter from "components/Sections/Footers/DefaultFooter";

// Coworking page sections
import Information from "pages/Website/Home/sections/Information";
import Testimonials from "pages/Website/Home/sections/Testimonials";
// import AboutUs from "pages/Website/Home/sections/AboutUs";
import Places from "pages/Website/Home/sections/Places";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/imgs/bg-home.png";

import { useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom";

function Coworking() {
    // const [controller, dispatch] = useMaterialUIController();
    // const { layout } = controller;
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const signup = () => {
        navigate(`/signup?email=${email}`)
    }
    
   // useEffect(() => {
    // setLayout(dispatch, "website")
   // }, []);

  return (
      <>
          <PageLayout>
    <MKBox variant="gradient" bgColor="white" shadow="sm" py={0.25}>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/login",
          label: "Login",
          color: "success",
        }}
        sticky
        transparent
        relative
                  />
              </MKBox>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={12}
            justifyContent={{ xs: "center" }}
            sx={{ textAlign: { xs: "center"} }}
          >
            <MKTypography
              variant="h1"
                              color="white"
                              width="100%"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              ONE Membership, Multiple Courses
                          </MKTypography>
                          <Stack direction="row" spacing={1} mt={6} mb={3} justifyContent="center" alignItems="center">
                              <MKBox p={1} bgColor='white' borderRadius="10px">
                                  <MKInput variant="outlined" label="Enter Your Email" onChange={event => setEmail(event.target.value)} />
                              </MKBox>
                              <MKButton variant="gradient" color="primary" size="medium" sx={{ 'max-height': "45px" }} onClick={signup}>
                                  SIGN UP FOR FREE
                              </MKButton>
                          </Stack>
            <MKTypography
              variant="body2"
              color="white"
              mt={1}
              pr={{md:20}}
              pl={{ md: 20 }}
              opacity={0.95}
            >
              ClubUp helps golfers save money, provides greater flexibility, and allows you to experience hundreds of beautiful courses with a membership plan that suits you.

                It's time to bring golf to the 21st century.
                          </MKTypography>
                          
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
              >
                  <Testimonials />
        <Information />
        
        {/* <AboutUs /> */}
        {/* <Places /> */}
        
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
</PageLayout>
    </>
  );
}

export default Coworking;
