/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid"; 

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 

// Material Dashboard 2 PRO React examples
import DefaultNavbar from "components/Sections/Navbars/DefaultNavbar";
import DefaultFooter from "components/Sections/Footers/DefaultFooter";

// ProductPage page components
import ProductImages from "pages/Website/CourseProfile/components/ProductImages";
import CourseInfo from "pages/Website/CourseProfile/components/CourseInfo"; 

import routes from "routes";
import footerRoutes from "footer.routes";

import { getCourseBySlug } from "api/courses";

function CourseProfile() {
    const location = useLocation();
    const slug = location.pathname.split("/").pop();
    const [course, setCourse] = useState({ name: "Course Name", description: "Course Description", images:[] });
    
    useEffect(() => {
        try {
            getCourseBySlug(slug).then((data) => {
                console.log(data)
                setCourse(data);
            });
        }
        catch (err) {
            console.log(err)
        }

    }, [])
    
    return (
        <>
            <MKBox variant="gradient" bgColor="white" shadow="sm" py={0.25}>
              <DefaultNavbar
                  routes={routes}
                  action={{
                      type: "external",
                      route: "/login",
                      label: "Login",
                      color: "success",
                  }}
                  transparent
                  relative
                  dark
              />
          </MKBox>
      <MKBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MKBox p={3}>
            <MKBox mb={3}>
              <MKTypography variant="h5" fontWeight="medium">
                Course Details
              </MKTypography>
            </MKBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ProductImages images={course.images} />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <CourseInfo course={course} />
              </Grid>
            </Grid>
          </MKBox>
        </Card>
      </MKBox>
            <DefaultFooter content={footerRoutes} />
            </>
  );
}

export default CourseProfile;
