import { apiURL, axios, axiosPrivate } from 'api/index';

const getSubscriptionProducts = async () => {
    try {
        const res = await axios.get(`${apiURL}/api/product/subscriptionProducts`)
        return res.data

    } catch (err) { console.log(err) }
    return [];
}
const purchaseSubscription = async (productId) => {
    const request = { productId }
    const res = await axiosPrivate.post(`${apiURL}/api/purchase/subscription?productId=${productId}`, request,
        {
            Headers: { 'content-type': 'application/json' },
            withCredentials: true
        });
    console.log(res.data);
    return res.data
}
const registerGolfer = async (formData) => {

        const request = {
            Handicap: formData.handicap,
            Username: formData.username,
            membershipNo: formData.membershipNo,
            Address: {
                AddressLine1: formData.address1,
                AddressLine2: formData.address2,
                City: formData.city,
                County: formData.county,
                Country: formData.country,
                Postcode: formData.postcode
            },
            Firstname: formData.firstName,
            Lastname: formData.lastName,
            email: formData.email,
            password: formData.password
            
        }
        const res = await axios.post(`${apiURL}/api/users/signup`, request,
            {
                Headers: { 'content-type': 'application/json' },
                withCredentials: true
            });
        console.log(res.data);
        return res.data 
}
export default getSubscriptionProducts
export { registerGolfer, purchaseSubscription, getSubscriptionProducts }