import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import image1 from "assets/images/products/product-details-1.jpg";
import image2 from "assets/images/products/product-details-2.jpg";
import image3 from "assets/images/products/product-details-3.jpg";
import image4 from "assets/images/products/product-details-4.jpg";
import Dropzone from "pages/App/ClubSettings/components/ImageUploader/components/CourseDropzone";
import { useDropzone } from 'react-dropzone';
import { useState, useEffect, useRef, useCallback } from "react";
import { saveCourseImage } from "api/courses"
import useAxiosPrivate from "hooks/useAxiosPrivate";

function ImageUploader(images) {  
    const [files, setFiles] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [additonalFiles, setAdditionalFiles] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const additionalOnDrop = (acceptedFiles) => {
        if (additonalFiles.length < 5 && (files.length + acceptedFiles.length) <= 5) {
            setAdditionalFiles(prev => prev.concat(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                isFile: true
            }))));
            console.log(acceptedFiles)
        }
        else {
            console.log("limit to 5");
        }
    }

    const featuredOnDrop = (acceptedFiles) => {
        if (files.length < 1 && (files.length + acceptedFiles.length) <= 1) {
            setFiles(prev => prev.concat(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                isFile:true
            }))));
        }
        else {
            console.log("limit to 1");
        }
    }
    const handleFeatureDelete = (index) => {
        // Remove the image at the specified index from the images array
        setFiles(prevImages => prevImages.filter((val, i) => i !== index));
    }
    const handleAdditionalDelete = (index) => {
        // Remove the image at the specified index from the images array
        setAdditionalFiles(prevImages => prevImages.filter((val, i) => i !== index));
    }

    const saveFeaturedImage = async () => {
        setIsDisabled(true);
        const res = await saveCourseImage(axiosPrivate, files, 0);
        setIsDisabled(false);
    }
    const saveAdditionalImages = async () => {
        setIsDisabled(true);
        const res = await saveCourseImage(axiosPrivate, additonalFiles, 1);
        setIsDisabled(false);
    }

    useEffect(() => {
        if (images.images !== undefined) {
            images.images.forEach((img) => {
                console.log(img)
                if (img.imageType === 0) {
                    setFiles(currentImgs => [...currentImgs, { preview: img.url, url:img.url,id:img.url, name: img.id }])
                }
                else if (img.imageType === 1) {
                    setAdditionalFiles(currentImgs => [...currentImgs, { preview: img.url, url: img.url, id: img.id, name: img.id }])
                }

            })
        }
    }, [images.images])

    return (
        <Card id="image-uploader">
            <MDBox p={3}>
                <MDTypography variant="h3">Course Images</MDTypography>
            </MDBox>
            <MDBox p={3}>
                <MDTypography variant="h5">Featured Image</MDTypography>
                <Dropzone onDrop={featuredOnDrop} files={files} handleDelete={handleFeatureDelete} />
                <MDButton color="primary" disabled={isDisabled} variant="gradient" onClick={saveFeaturedImage}>Save</MDButton>
            </MDBox>

            <MDBox p={3}>
                <MDTypography variant="h5">Additional Images</MDTypography>
                <Dropzone onDrop={additionalOnDrop} files={additonalFiles} handleDelete={handleAdditionalDelete} />
                <MDButton color="primary" variant="gradient" disabled={isDisabled} onClick={saveAdditionalImages}>Save</MDButton>
            </MDBox>
            
        </Card>
    )
}

export default ImageUploader;