/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';
import 'custom.css'
import App from "App";

import { Provider } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { MaterialUIControllerProvider } from "context";

// Make sure to call `loadStripe` outside of a component�s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51LyvKcASb5YixGTAtm81Gnt2Arzt72r9b7u9CsQP9jSJe5rh74FfGrYDHm236ap8W9X5zRCPffxkfoQcaGB6UDv600f8R7SC05');

ReactDOM.render(
    <React.StrictMode>
            <BrowserRouter>
            <MaterialUIControllerProvider>
                <Elements stripe={stripePromise}>
                    <App />
                </Elements>
                </MaterialUIControllerProvider>
            </BrowserRouter>
    </React.StrictMode>,
  document.getElementById("root")
);
