/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBadge from "components/MKBadge";

function CourseInfo({ course }) {
     
  return (
    <MKBox>
      <MKBox mb={1}>
        <MKTypography variant="h3" fontWeight="bold">
                  {course.name}
        </MKTypography>
      </MKBox>
      
      <MKBox mt={1}>
        <MKTypography variant="h5" fontWeight="medium">
          Credits
        </MKTypography>
      </MKBox>
      <MKBox mb={1}>
        <MKTypography variant="h6" fontWeight="medium">
          From 25
        </MKTypography>
      </MKBox>
          <MKBadge color="success" badgeContent={`Par ${course.par}`} container sx={{mr:1}} />
          <MKBadge color="success" badgeContent={`Holes ${course.holes}`} container />
      <MKBox mt={3} mb={1} ml={0.5}>
        <MKTypography variant="button" fontWeight="regular" color="text">
          Description
        </MKTypography>
      </MKBox>
      <MKBox m={0} pl={4} mb={2}>
          <MKTypography variant="body2" color="text" fontWeight="regular">
                  {course.description}
          </MKTypography>
      </MKBox>
      
      <MKBox mt={3}>
        <Grid item xs={12} lg={5} container>
          <MKButton variant="gradient" color="success" fullWidth>
            Book
          </MKButton>
        </Grid>
      </MKBox>
    </MKBox>
  );
}
CourseInfo.propTypes = {
    course: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired
};
export default CourseInfo;
