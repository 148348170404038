import { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DefaultNavbar from "components/Sections/Navbars/DefaultNavbar";
import DefaultFooter from "components/Sections/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";

import validations from "pages/Website/SignUp/schemas/validations";
import form from "pages/Website/SignUp/schemas/form";
import initialValues from "pages/Website/SignUp/schemas/initialValues";

// Wizard page components
import UserInfo from "pages/Website/SignUp/components/UserInfo";
import Address from "pages/Website/SignUp/components/Address";
import Membership from "pages/Website/SignUp/components/Completed";

// API
import { registerGolfer } from 'api/products';
import Completed from "./components/Completed";

function getSteps() {
    return ["About", "Address", "Login"];
}

function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
        case 0:
            return <UserInfo formData={formData} />;
        case 1:
            return <Address formData={formData} />;
        case 2:
            return <Completed />; 
        default:
            return null;
    }
}

function Wizard() {
    const [activeStep, setActiveStep] = useState(0);
    const [header, setHeader] = useState('Become a ClubUp Member');
    const [subheader, setSubHeader] = useState('Get access to many different courses and exclusive offers!');
    const [mainButton, setMainButton] = useState('Next');
    const [errMsg, setErrMsg] = useState('');
    const steps = getSteps();
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const isAccountCreateStep = activeStep === steps.length - 2;
    const navigate = useNavigate()
    const location = useLocation();
    const email = new URLSearchParams(location.search).get('email');
    initialValues.email = email
    useEffect(() => {
        switch (activeStep) {
            case 1:
                setMainButton('Create Account')
                break;
            case 2:
                setMainButton('Login')
                break;
                
            default:
                setMainButton('Next')
                break;
        }
       

    }, [activeStep]);
    const handleBack = () => setActiveStep(activeStep - 1);

    const submitForm = async (values, actions) => {
        try {
            const response = await registerGolfer(values);
            setActiveStep(activeStep + 1);
        }
        catch (err) {
            if (!err?.response?.status) {
                setErrMsg('No Server Response')
            }
            else if (err.response?.status === 400) {
                setErrMsg(err.response?.data)
            }
            else {
                setErrMsg('Login Failed')
            }
        }

        

        actions.setSubmitting(false);
        // actions.resetForm();

        // setActiveStep(0);
    }; 

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            navigate('/login')
        }
        else if (isAccountCreateStep)
        {
            
            submitForm(values, actions);
        }
        else {
            setActiveStep(activeStep + 1);
            
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    return (
        <>
        <DefaultNavbar
                routes={routes}
                action={{
                    type: "internal",
                    route: "/login",
                    label: "Login",
                    color: "success",
                }}
                sticky
            />
            <MDBox py={3} mb={20} >
                <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
                    <Grid item xs={12} lg={8}>
                        <MDBox mt={6} mb={8} textAlign="center">
                            <MDBox mb={1}>
                                <MDTypography variant="h3" fontWeight="bold">
                                    {header}
                                </MDTypography>
                            </MDBox>
                            <MDTypography variant="h5" fontWeight="regular" color="secondary">
                                {subheader}
                            </MDTypography>
                        </MDBox>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                <Form id={formId} autoComplete="off">
                                    <Card sx={{ height: "100%" }}>
                                        <MDBox mx={2} mt={-3}>
                                            <Stepper activeStep={activeStep} alternativeLabel>
                                                {steps.map((label) => (
                                                    <Step key={label}>
                                                        <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </MDBox>
                                        <MDBox p={3}>
                                            <MDBox>
                                                {getStepContent(activeStep, {
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                    setFieldValue
                                                })}
                                                <MDTypography inline align="right" variant="text" color="error">{errMsg}</MDTypography>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                                                    {activeStep === 0 || activeStep === 2 ? (
                                                        <MDBox />
                                                    ) : (
                                                        <MDButton variant="gradient" color="light" onClick={handleBack}>
                                                            back
                                                        </MDButton>
                                                    )}
                                                    <MDButton
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        variant="gradient"
                                                        color="dark"
                                                    >
                                                        {mainButton}
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </MDBox>
           
            <MDBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MDBox>
            </>
    );
}

export default Wizard;
