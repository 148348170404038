/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";

// Settings page components
import FormField from "pages/App/ClubSettings/components/BasicInfo/components/FormField";




function BasicInfo(course) {
     
    const [editorValue, setEditorValue] = useState(
        "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
    );
    

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h3">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <MDTypography variant="h5">Name</MDTypography>
                <MDTypography variant="subtitle1">{course.course.name}</MDTypography>
            </Grid>
          
          <Grid item xs={12} sm={6}>
            <MDTypography variant="h5">Par</MDTypography>
            <MDTypography variant="subtitle1">{course.course.par}</MDTypography>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <MDTypography variant="h5">Holes</MDTypography>
            <MDTypography variant="subtitle1">{course.course.holes}</MDTypography>
          </Grid>
            <Grid item xs={12} md={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography variant="h5">Description</MDTypography>
                <MDTypography variant="subtitle1">{course.course.description}</MDTypography>
            </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
                <MDTypography variant="caption">If you wish to update Course Basic Info please contact courses@clubup.co.uk</MDTypography>
            </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
